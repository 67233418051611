import React from 'react';
import numberFormatter from 'number-formatter';
import {connect} from 'react-redux';
import {withRouter, Link} from 'react-router-dom';
import moment from 'moment';
import Immutable from 'seamless-immutable';
import * as invoiceActions from '../../actions/invoice';
import LoadingScreen from '../LoadingScreen';
import PageHeader from '../boxes/PageHeader';
import PageBody from '../boxes/PageBody';
import MainContent from '../boxes/MainContent';


class InvoiceListComponent extends React.Component{

    componentDidMount(){
        const {dispatch, user} = this.props;
        dispatch(invoiceActions.getClientInvoices(user.credentials.accessToken, user.companyInfo.id));
    }

    renderLoading(){
        return (
            <MainContent>
                <PageBody>
                    <LoadingScreen/>
                </PageBody>
            </MainContent>
        )
    }
    
    render(){

        const { isFetching, invoices } = this.props;

        if(isFetching) return this.renderLoading();

        return (
            <MainContent>
                <PageHeader title="Invoices"/>
                <PageBody>
                    <table className="dashboard__table">
                        <thead>
                        <tr>
                            <th>Status</th>
                            <th>Invoice Code</th>
                            <th>Description</th>
                            <th>Amount (NGN)</th>
                            <th>Date Issued</th>
                        </tr>
                        </thead>
                        <tbody>
                        {invoices.map((invoice)=>(
                            <tr key={invoice.reference_code}>
                                <td>{ getInvoiceStatusIndicator(invoice.payment_status) }</td>
                                <td><Link to={`/dashboard/invoices/${invoice.reference_code}`}>{invoice.reference_code}</Link></td>
                                <td>{invoice.description}</td>
                                <td>{`${numberFormatter("#,###.00",invoice.total_price)}`}</td>
                                <td>{moment(invoice.created_at).format("dddd, MMMM Do YYYY")}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </PageBody>
            </MainContent>
        )
    }
}

const getInvoiceStatusIndicator = (status) => {
    if(status === 1){
        return 'Not Paid';
    }else if(status === 2){
        return 'Part Paid';
    }else{
        return 'Payment Complete';
    }
};

const mapStateToProps = ({invoice}) => ({
    isFetching: invoice.isFetching,
    invoices: invoice.invoices ? Immutable.asMutable(invoice.invoices) : {},
    error: invoice.error ? Immutable.asMutable(invoice.error) : {},
    alert: invoice.alert
});

export default withRouter(connect(mapStateToProps)(InvoiceListComponent));